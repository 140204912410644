import { Checkout } from '@wix/ambassador-checkout/types';
import {
  GiftCardCatalogOptions,
  GiftCardProduct,
  IPurchaseOptions,
  GiftCardProductVariant,
} from '../../../../types';
import { AppServices } from '../components/GiftCard/controller/initializeServices';
import { makeAutoObservable } from 'mobx';

interface IPurchaseOptionsValidation {
  recipientEmail: boolean;
  quantity: boolean;
  customAmount: boolean;
}

export class CheckoutStore {
  purchase_options_validation: IPurchaseOptionsValidation = {
    recipientEmail: false,
    quantity: true,
    customAmount: false,
  };
  show_errors: boolean = false;
  creatingCheckout: boolean = false;
  createdCheckout?: Checkout;
  redirect_path?: string;

  constructor(private readonly services: AppServices) {
    makeAutoObservable(this);
  }

  setPurchaseOptionsValidation = (
    key: keyof IPurchaseOptionsValidation,
    value: boolean,
  ) => {
    this.purchase_options_validation[key] = value;
  };

  checkValidation = (keys: (keyof IPurchaseOptionsValidation)[]) => {
    return !keys.some((key) => !this.purchase_options_validation[key]);
  };

  isValidPurchaseOptions = (purchase_options: IPurchaseOptions): Boolean => {
    const keysToValidate: (keyof IPurchaseOptionsValidation)[] = ['quantity'];
    if (purchase_options.isGift) {
      keysToValidate.push('recipientEmail');
    }
    if (purchase_options.variantId === 'custom') {
      keysToValidate.push('customAmount');
    }
    return this.checkValidation(keysToValidate);
  };

  splitFullName = (fullName: string): { first: string; last: string } => {
    const [first, ...last] = fullName.split(' ').filter(Boolean);
    return {
      first,
      last: last.join(' '),
    };
  };

  buildGiftCardCatalogOptions = (
    purchase_options: IPurchaseOptions,
    currencyCode: string,
  ): GiftCardCatalogOptions => {
    const {
      variantId,
      recipientEmail,
      recipientName,
      deliverAt,
      greetingMessage,
      quantity,
      isGift,
      customAmount,
    } = purchase_options;
    this.creatingCheckout = true;

    const checkoutPurchaseOptions: GiftCardCatalogOptions = {
      quantity,
      currency: currencyCode,
    };

    if (variantId === 'custom') {
      checkoutPurchaseOptions.customAmount = customAmount;
    } else {
      checkoutPurchaseOptions.variantId = variantId;
    }

    if (isGift) {
      checkoutPurchaseOptions.giftingInfo = {
        recipientInfo: {
          firstName: this.splitFullName(recipientName).first,
          lastName: this.splitFullName(recipientName).last,
          email: recipientEmail,
        },
        greetingMessage,
      };

      if (deliverAt) {
        checkoutPurchaseOptions.giftingInfo.deliverAt = deliverAt;
      }
    }

    return checkoutPurchaseOptions;
  };

  createCheckout = async (
    productId: GiftCardProduct['product_id'],
    purchase_options: IPurchaseOptions,
    currencyCode: string,
  ) => {
    try {
      this.show_errors = true;
      const isValid = this.isValidPurchaseOptions(purchase_options);
      if (!isValid) {
        return;
      }

      this.creatingCheckout = true;
      const checkoutPurchaseOptions = this.buildGiftCardCatalogOptions(
        purchase_options,
        currencyCode,
      );
      const { checkout, redirect_path } =
        await this.services.checkoutService.createCheckout(
          productId,
          checkoutPurchaseOptions,
        );

      this.createdCheckout = checkout;
      this.redirect_path = redirect_path;
    } catch (error) {}
  };

  reportCheckout = async ({
    product,
    purchase_options,
    currencyCode,
  }: {
    product: GiftCardProduct;
    purchase_options: IPurchaseOptions;
    currencyCode: string;
  }) => {
    const presetVariants = product?.variants.filter(
      (variant) => variant.type === 'preset',
    ) as GiftCardProductVariant[];

    const selectedVariant = presetVariants.find(
      (variant) => variant.id === purchase_options.variantId,
    );

    if (selectedVariant) {
      this.services.checkoutService.reportCheckout({
        productId: product.product_id,
        checkoutId: this.createdCheckout?.id,
        purchase_options,
        price: selectedVariant.price,
        currencyCode,
      });
    }
  };

  toProps() {
    return {
      purchase_options_validation: this.purchase_options_validation,
      setPurchaseOptionsValidation: this.setPurchaseOptionsValidation,
      show_errors: this.show_errors,
      creatingCheckout: this.creatingCheckout,
      checkout: this.createdCheckout,
      redirect_path: this.redirect_path,
      createCheckout: this.createCheckout,
      reportCheckout: this.reportCheckout,
    };
  }
}
