import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { GiftCardController } from './controller/GiftCardController';

const Controller: CreateControllerFn = async (controllerParams) => {
  const controller = await GiftCardController(controllerParams);

  return {
    pageReady: () => controller.init(),
    exports: () => controller.exports(),
  };
};

export default Controller;
