import jwtDecode from 'jwt-decode';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { CheckoutService } from '../../../services/CheckoutService';
import { GiftCardService } from '../../../services/GiftCardService';

export type AppServices = {
  controllerParams: ControllerParams;
  checkoutService: CheckoutService;
  giftCardService: GiftCardService;
};

export const initializeServices = async (
  controllerParams: ControllerParams,
): Promise<AppServices> => {
  const checkoutService = new CheckoutService(controllerParams);
  const giftCardService = new GiftCardService(controllerParams);

  const { metaSiteId } = jwtDecode(
    controllerParams.controllerConfig.appParams.instance,
  ) as {
    metaSiteId: String;
  };

  controllerParams.flowAPI?.bi?.updateDefaults({
    appDefId: controllerParams.controllerConfig.appParams.appDefinitionId,
    instanceId: controllerParams.controllerConfig.appParams.instanceId,
    msid: metaSiteId,
  });

  return {
    controllerParams,
    checkoutService,
    giftCardService,
  };
};
