import { ControllerParams } from '@wix/yoshi-flow-editor';
import { ControllerFactoryResult } from '../../../types/app.types';
import { createStores } from './createStores';
import { createUpdateComponent } from './createUpdateComponent';
import { initializeServices } from './initializeServices';

export const GiftCardController = async (
  controllerParams: ControllerParams,
): Promise<ControllerFactoryResult> => {
  const stores = await initializeServices(controllerParams).then(createStores);
  const updateComponent = createUpdateComponent(controllerParams, stores);

  return {
    init: () => Promise.resolve(updateComponent()),
    load: () => Promise.resolve(updateComponent()),
    onStyleUpdate: () => null,
    getFreeTexts: () => [],
    exports: () => ({
      setCustomVariantDisplay: (foramt: string) => {
        stores.giftCardStore.setCustomVariantDisplay(foramt);
      },
    }),
  };
};
